
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { CarMaintainInfo } from '@/types/carManage'

@Component({
  name: 'AddMaintain'
})
export default class extends Vue {
  private info: CarMaintainInfo = {
    projectId: '',
    vehicleId: '',
    vehicleType: '',
    repairTime: '',
    repairAddress: '',
    repairReason: '',
    repairPrice: '',
    brand: '',
    model: '',
    vehicleTypeName: ''
  }

  private submitShow = false
  private machineList: CarMaintainInfo[] = []
  private rules = {
    projectId: [{ required: true, message: '请选择所属项目', trigger: 'change' }],
    vehicleId: [{ required: true, message: '请选择车牌号', trigger: 'change' }],
    repairAddress: [
      { required: true, message: '请输入维修地点', trigger: 'blur' }
    ],
    repairTime: [
      { required: true, message: '请选择维修时间', trigger: 'change' }
    ]
  }

  private carTypeList = []

  get repairId () {
    return this.$route.params.id as string || ''
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.repairId && this.getDetail(this.repairId)
    this.getCarTypeList()
  }

  getDetail (repairId: string) {
    this.$axios.get(this.$apis.carManage.selectYhVehicleRepairByRepairId, { repairId }).then((res) => {
      this.info = res
      this.getMachineList()
    })
  }

  // 车辆类型
  getCarTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'vehicleType' }).then(res => {
      this.carTypeList = res.vehicleType || []
    })
  }

  // 车牌号
  getMachineList () {
    this.$axios.get(this.$apis.carManage.selectYhVehicleByPage, {
      projectId: this.info.projectId
    }).then((res) => {
      this.machineList = res.list || []
    })
  }

  // 项目改变
  changeProject (value: string) {
    this.info.vehicleId = ''
    this.info.vehicleTypeName = ''
    this.info.brand = ''
    this.info.model = ''
    value && this.getMachineList()
  }

  changeMachine (value: string) {
    const res = this.machineList.find((item) => item.vehicleId === value)
    if (res) {
      this.info.vehicleTypeName = res.vehicleTypeName
      this.info.brand = res.brand
      this.info.model = res.model
    }
  }

  saveForm () {
    ;(this.$refs.form as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const urls = this.$route.params.id
          ? this.$apis.carManage.updateYhVehicleRepair
          : this.$apis.carManage.insertYhVehicleRepair
        // 不需要传这三个参数
        delete this.info.vehicleTypeName
        delete this.info.brand
        delete this.info.model
        this.$axios
          .post(urls, this.info)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.back()
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }
}
